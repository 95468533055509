import Image from "next/image";
import React from "react";

import heroImage from "../../../../public/images/crm-min.webp";
const Hero = () => {
  return (
    <div className="relative overflow-hidden bg-neutral-background">
      <main className="lg:relative">
        <div className="w-full pt-16 pb-20 mx-auto text-center max-w-7xl lg:py-48 lg:text-left">
          <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
            <h1 className="text-4xl font-extrabold tracking-tight text-neutral-text sm:text-5xl md:text-6xl">
              <span className="block xl:inline">WinShop</span>
              <span className="block text-primary-text xl:inline">CRM</span>
            </h1>
            <p className="mt-3 text-base text-neutral-textSubtle sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
              Umožňuje lépe třídit důležité informace o zákaznících a zároveň efektivněji
              vyhodnocovat obchodní aktivity společnosti. Primárním cílem CRM, je zaměření na
              přehledné shromažďování veškerých potřebných údajů o zákaznících společnosti,
              sledování komunikace se zákazníky, proces obchodních příležitostí a také efektivní
              plánování aktivit společnosti s možností vytvářet přehledné statistiky. S tímto
              nástrojem lze pracovat v jakémkoliv oddělení, ať už se jedná o obchod, marketing, či o
              jinou oblast.
            </p>
          </div>
        </div>
        <div className="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
          <Image
            className="absolute inset-0 object-cover w-full h-full"
            fill
            src={heroImage}
            sizes="50vw"
            alt=""
          />
        </div>
      </main>
    </div>
  );
};

export default Hero;
