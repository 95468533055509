import Head from "next/head";
import { getSession } from "next-auth/react";

import LandingPageComponent from "../components/Home/LandingPage/Home";
import HomeComponent from "../components/Home/SignedIn/Home";
import SignedInLayout from "../components/Layouts/Navbar/SignedIn/SignedInLayout";

export default function Home({ session }) {
  if (!session) {
    return <LandingPageComponent />;
  }

  return (
    <SignedInLayout session={session}>
      <Head>
        <title>WinShopCRM</title>
      </Head>
      <main>{session && <HomeComponent session={session} />}</main>
    </SignedInLayout>
  );
}

export async function getServerSideProps(context) {
  return {
    props: {
      session: await getSession(context),
    },
  };
}
