import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import Link from "next/link";
import { Fragment } from "react";

import { WinShopLogo } from "@winshop-crm/ui";

const navigation = [
  // { name: "Informace", href: "/info" },
  { name: "Kontakty", href: "https://www.pokladny.com/kontakty.html" },
];

const Navbar = () => {
  return (
    <div className="sticky top-0 z-50 max-w-screen">
      <div className="relative w-full overflow-visible bg-white">
        <div className="mx-auto max-w-7xl">
          <div className="relative z-10 pb-1 bg-white sm:pb-2 md:pb-4 lg:w-full lg:pb-6 xl:pb-8">
            <Popover>
              {({ open }) => (
                <>
                  <div className="relative w-full px-4 pt-6 sm:px-6 lg:px-8">
                    <nav
                      className="relative flex items-center justify-between sm:h-10 lg:justify-start"
                      aria-label="Global"
                    >
                      <div className="flex items-center flex-grow shrink mr-auto lg:flex-grow-0y">
                        <div className="flex items-center justify-between md:w-auto">
                          <Link href="/" passHref>
                            <WinShopLogo />
                          </Link>
                          <div className="flex items-center -mr-2 md:hidden">
                            <Popover.Button className="inline-flex items-center justify-center p-2 rounded-md bg-neutral-background text-primary-action hover:text-primary-actionHover hover:bg-neutral-backgroundSubtle focus:outline-none">
                              <span className="sr-only">Otevřít menu</span>
                              <MenuIcon className="w-6 h-6" aria-hidden="true" />
                            </Popover.Button>
                          </div>
                        </div>
                      </div>
                      <div className="hidden md:flex md:ml-10 md:pr-4 md:space-x-8 md:flex-row">
                        {navigation.map((item) => (
                          <Link key={item.name} href={item.href}>
                            <div className="font-medium text-neutral-text">{item.name}</div>
                          </Link>
                        ))}
                        <Link href="/auth/signIn">
                          <div className="font-medium text-primary-text ">Přihlásit se</div>
                        </Link>
                      </div>
                    </nav>
                  </div>

                  <Transition
                    show={open}
                    as={Fragment}
                    enter="duration-150 ease-out"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="duration-100 ease-in"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Popover.Panel
                      focus
                      static
                      className="absolute inset-x-0 top-0 z-50 p-2 transition origin-top-right transform md:hidden"
                    >
                      <div className="overflow-hidden bg-white rounded-lg shadow-md ring-1 ring-black ring-opacity-5">
                        <div className="flex items-center justify-between px-5 pt-4">
                          <WinShopLogo className="w-auto h-8" />
                          <div className="-mr-2">
                            <Popover.Button className="inline-flex items-center justify-center p-2 rounded-md bg-neutral-background text-primary-action hover:text-primary-actionHover hover:bg-neutral-backgroundSubtle focus:outline-none">
                              <span className="sr-only">Close main menu</span>
                              <XIcon className="w-6 h-6" aria-hidden="true" />
                            </Popover.Button>
                          </div>
                        </div>
                        <div className="px-2 pt-2 pb-3 space-y-1">
                          {navigation.map((item) => (
                            <Link key={item.name} href={item.href}>
                              <div className="block px-3 py-2 text-base font-medium rounded-md text-neutral-text hover:bg-neutral-actionSecondaryHover">
                                {item.name}
                              </div>
                            </Link>
                          ))}
                        </div>
                        <Link href="/auth/signIn">
                          <div className="block w-full px-5 py-3 font-medium text-center text-primary-text bg-neutral-actionSecondary hover:bg-neutral-actionSecondaryHover">
                            Přihlásit se
                          </div>
                        </Link>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
