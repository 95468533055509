// NextJS
import Link from "next/link";
// State
import { useSelector } from "react-redux";

// Components
import { WSAvatar, WSBox } from "@winshop-crm/ui";

const Home = ({ session }) => {
  const user = useSelector((state) => state.user);

  return (
    <>
      {session && user && (
        <>
          <section className="mt-8" aria-labelledby="profile-overview-title">
            <WSBox className="overflow-hidden bg-white">
              <div className="p-6 bg-white">
                <div className="sm:flex sm:items-center sm:justify-between">
                  <div className="flex flex-col items-center justify-center sm:flex-row sm:space-x-5">
                    <div className="shrink">
                      <WSAvatar userName={user.userName} src={user.photo} size="xl" />
                    </div>
                    <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                      <p className="text-sm font-medium text-neutral-textSubtle">Vítejte zpět,</p>
                      <p className="text-xl font-bold text-neutral-text sm:text-2xl">
                        {user.fullName}
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-center mt-5 sm:mt-0">
                    <Link href="/user/settings">
                      <div className="flex items-center justify-center px-4 py-2 text-sm font-medium bg-white border rounded-md shadow-sm border-neutral-border text-neutral-text hover:bg-neutral-actionSecondaryHover">
                        Zobrazit profil
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </WSBox>
          </section>
        </>
      )}
    </>
  );
};

export default Home;
