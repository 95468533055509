import Head from "next/head";

import Features from "./Features";
import Hero from "./Hero";
import LandingLayout from "../../Layouts/Navbar/LandingLayout/LandingLayout";

const Home = () => {
  return (
    <LandingLayout>
      <Head>
        <title>WinShopCRM</title>
      </Head>
      <>
        <Hero />
        <Features />
      </>
    </LandingLayout>
  );
};

export default Home;
