import React from "react";

import Footer from "./Footer";
import Navbar from "./Navbar";

const LandingLayout = (props) => {
  return (
    <div className="bg-blue-300">
      <Navbar />
      {props.children}
      <Footer />
    </div>
  );
};

export default LandingLayout;
